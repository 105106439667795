import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import emailjs from "emailjs-com";
import * as yup from "yup";

const CustomErrorMessage = ({ show, msg, direction = "left" }) => {
  const visibility = show ? "visible" : "invisible";
  const errorMessage = msg || "-"; // Prevents form to jump up when error message is empty
  return (
    <p className={`text-kb_warning ${visibility} text-${direction}`}>
      {errorMessage}
    </p>
  );
};

const fieldStyle = { border: "1px solid #C4C4C4", borderRadius: "5px" };

const validationSchema = yup.object({
  "Ditt navn": yup.string().required("Navn er påkrevd!").max(55),
  "E-post": yup
    .string()
    .email("Gyldig e-post er påkrevd")
    .required("E-post er påkrevd!")
    .max(128),
  Telefon: yup.string().max(12, "Telefonnr er for lang!"),
  Melding: yup
    .string()
    .max(2048, "Meldingen er for lang!")
    .required("Melding er påkrevd!")
    .min(3, "Meldingen er for kort"),
});

const ContactForm = () => {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  const feedbackColor = feedbackSuccess ? "text-kb_success" : "text-kb_warning";

  const handleFeedback = (msg, success) => {
    setFeedbackMessage(msg);
    setFeedbackSuccess(success);
    setTimeout(() => {
      setFeedbackMessage("");
      setFeedbackSuccess(false);
    }, 5000);
  };

  const sendEmail = async (data, actions) => {
    try {
      emailjs.send(
        "service_luys9ic",
        "template_8nx9i5s",
        {
          Telefon: data.Telefon,
          Melding: data.Melding,
          navn: data["Ditt navn"],
          Epost: data["E-post"],
        },
        "user_0PNADA1F5aiEMIhbuYNcW"
      );
      actions.resetForm();
      handleFeedback("Takk for din henvendelse!", true);
    } catch (e) {
      console.error("Error sending email:\n", e);
      handleFeedback("Noe gikk galt, prøv igjen senere!", false);
    }
  };
  return (
    <section style={{ maxWidth: "1010px" }} className='w-full m-auto mb-12 px-4 text-kb_gray'>
      <div className='my-8'>
        <h1 className='text-kb_blue text-3xl font-bold '>Kontakt oss</h1>
        <p style={{ maxWidth: "440px" }}>
          Lurer du på noe? Ta kontakt med oss på{" "}
          <a className='hover:underline' href='mailto:firmapost@knutbjerke.no'>
            firmapost@knutbjerke.no
          </a>
          , ring oss på 69 28 99 33 eller brukt kontaktskjemaet.
        </p>
      </div>

      <Formik
        initialValues={{
          "Ditt navn": "",
          "E-post": "",
          Telefon: "",
          Melding: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, actions) => sendEmail(data, actions)}
      >
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form className='flex flex-col'>
              <div className='flex flex-col md:flex-row justify-between '>
                <div className='flex flex-col md:w-5/12 w-full'>
                  <label
                    className='text-kb_gray font-bold '
                    htmlFor='Ditt navn'
                  >
                    Ditt navn:
                  </label>
                  <Field
                    id='Ditt navn'
                    name='Ditt navn'
                    type='input'
                    style={fieldStyle}
                    className='h-10 mb-2 pl-2'
                  />
                  <CustomErrorMessage
                    show={errors["Ditt navn"] && touched["Ditt navn"]}
                    msg={errors["Ditt navn"]}
                  />
                  {/* <ErrorMessage name="Ditt navn" className="invalid-feedback" /> */}
                </div>
                <div className='flex flex-col md:w-5/12 w-full'>
                  <label className='text-kb_gray font-bold' htmlFor='E-post'>
                    E-post:
                  </label>
                  <Field
                    id='E-post'
                    name='E-post'
                    style={fieldStyle}
                    className='h-10 mb-2 pl-2'
                    type='email'
                  />
                  <CustomErrorMessage
                    direction='right'
                    show={errors["E-post"] && touched["E-post"]}
                    msg={errors["E-post"]}
                  />
                </div>
                {/* <ErrorMessage name="E-post" className="invalid-feedback" /> */}
              </div>
              <div className='flex flex-col md:flex-row justify-between '>
                <div className='flex flex-col md:w-5/12 w-full'>
                  <label className='text-kb_gray font-bold' htmlFor='Telefon'>
                    Telefon:
                  </label>
                  <Field
                    id='Telefon'
                    name='Telefon'
                    style={fieldStyle}
                    className='h-10 mb-2 pl-2'
                    type='text'
                  />
                  <CustomErrorMessage
                    show={errors["telefon"] && touched["telefon"]}
                    msg={errors["telefon"]}
                  />
                  {/* <ErrorMessage name="telefon" className="invalid-feedback" /> */}
                </div>
                <div className='flex flex-col md:w-5/12 w-full'>
                  <label className='text-kb_gray font-bold' htmlFor='Melding'>
                    Melding:
                  </label>
                  <Field
                    as='textarea'
                    id='Melding'
                    name='Melding'
                    style={fieldStyle}
                    className='h-40 mb-2 pl-2 pt-2'
                    type='text'
                  />
                  <CustomErrorMessage
                    direction='right'
                    show={errors["Melding"] && touched["Melding"]}
                    msg={errors["Melding"]}
                  />
                  {/* <ErrorMessage name="Melding" className="invalid-feedback" /> */}
                </div>
              </div>
              <div className='flex justify-end items-end'>
                <button
                  aria-label='Send'
                  disabled={isSubmitting}
                  type='submit'
                  
                  className='rounded bg-kb_blue w-full md:w-60 py-2 text-kb_white'
                >
                  Send
                </button>
                <p className={`text-center h-1 ${feedbackColor}`}>
                  {!!feedbackMessage && feedbackMessage}
                </p>
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default ContactForm;
