import sanityClient from "@sanity/client";
import imageUrlBuilder from '@sanity/image-url'


const client =  sanityClient({
  projectId: "qme7y1bf",
  dataset: "production",
  apiVersion: "2021-07-25",
  useCdn: true,
});

const builder = imageUrlBuilder(client)

export function urlFor(source) {
  return builder.image(source)
    
}
export default client;