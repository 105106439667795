import ContactForm from "../components/ContactForm";
import "./contact.css";
import React from "react";

const Contact = () => {
  return (
    <main className='flex flex-col items-center min-h-screen w-full m-auto'>
      <section className='contact-main-banner flex justify-around'></section>
      <ContactForm />
      <section className='flex flex-col justify-between mb-12 text-center w-2/3 lg:w-1/3'>
        <h1 className='text-xl lg:text-2xl font-bold mb-2'>Informasjon</h1>
        <address className='not-italic text-kb_gray text-sm font-light'>
          Knut Bjerke AS <br />
          Granittveien 10 <br />
          <br />
          Moss og Våler næringspark <br />
          1592 Våler i Viken <br />
          <br />
          Tlf: 69289933/mobil 90519095 <br />
          <br />
          Fakturaadresse:
          <br /> firmapost@knutbjerke.no
          <br />
          <br />
          org.nr 910 611 879
        </address>
      </section>
    </main>
  );
};

export default Contact;
